/*Efecto*/
.modalmask {
    position: fixed;
    font-family: Arial, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
    opacity:0;

    pointer-events: none;
}
.modalmask_target {
    opacity:1;
    pointer-events: auto;
    
}

/*Formato de la ventana*/
.modalbox{
    width: 400px;
    position: relative;
    padding: 5px 20px 13px 20px;
    background: #fff;
    border-radius:3px;
    

     
}

/*Movimientos*/
.movedown {
    
    margin:10% auto;
}



.modalmask:target .movedown{       
    margin:10% auto;
}


/*Boton de cerrar*/
.close {
    background: #606061;
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 1px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    border-radius:3px;
 
}
 
.close:hover {
    background: #FAAC58;
    color:#222;
}