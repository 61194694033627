.placa-input {
    background-color: #FFEB3B; /* Amarillo */
    color: #000000; /* Negro */
    font-size: 2rem; /* Tamaño de letra grande */
    font-weight: bold; /* Letras en negrita */
    padding: 10px 20px; /* Aumentar el tamaño del input */
    border: 2px solid #000; /* Borde negro */
    border-radius: 8px; /* Bordes redondeados */
    width: 100%; /* Ancho completo */
    max-width: 400px; /* Máximo tamaño */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

.placa-input::placeholder {
    color: #000000; /* Placeholder negro */
    opacity: 0.6; /* Un poco más transparente */
}