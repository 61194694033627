.boton-flotante {
    position: fixed;
    bottom: 300px; /* Ajusta la distancia desde la parte inferior */
    left: 20px; /* Ajusta la distancia desde la izquierda */
    background-color: #007bff; /* Color de fondo */
    color: white; /* Color del texto */
    padding: 10px 20px; /* Espaciado interno */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cambia el cursor al pasar por encima */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombra del botón */
    animation: moverBoton 1s ease-in-out infinite; /* Animación para el movimiento */
    z-index: 1500; /* Asegúrate de que esté encima del modal */
  }
  
  @keyframes moverBoton {
    0% {
      transform: translateY(0); /* Posición inicial */
    }
    50% {
      transform: translateY(-10px); /* Mueve hacia arriba */
    }
    100% {
      transform: translateY(0); /* Regresa a la posición inicial */
    }
  }
  