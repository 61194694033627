@media screen and (max-width: 700px) {
    

    *{
        font-size: 18px;
    }
        
    .btn {
        
        padding:   10px;
    }

    .btn-xs {
        
        font-size: 15px !important;
    }

    .list-group-item-xs {

        padding: 20px 5px !important;
    }

    .min-width-400{
        min-width: 200px !important;
    }

    .badge{
        white-space: normal !important;
    }

    .estiloPrecios{
        background: rgb(179, 179, 179);
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 0px;
      
    }

    .btn-movil{
        padding-top: 18px;
        padding-bottom: 18px;
    }
 }
 