.keyboard {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .key {
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .key:hover {
    background-color: #e0e0e0;
  }
  
  .key:active {
    background-color: #c0c0c0;
  }