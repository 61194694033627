.text-principal-2{
  color: #103928 ;
 
}


/* Agrega este estilo en tu archivo CSS */
.react-confirm-alert {
  z-index: 1100; /* Un valor mayor que el modal de Bootstrap (por defecto es 1050) */
}

.react-confirm-alert-overlay {
  z-index: 1090; /* Asegura que el fondo del modal de confirmación también se muestre */
}


.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 200px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow: hidden;
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -14px;
    display: block;
    font-family: 'Notification';
    width: 10px;
    height: 10px;
    font-size: 15px;
    text-align: center;
    line-height: 28px;
}

.notification {
    box-sizing: border-box;
    padding: 5px 5px 5px 10px;

}

.notification-message{
    margin-left: 12px;
}








/************ PROPOVER ***********/
/* Base styling*/

  
  /* Popover styling */
  

  .popover__title {
    font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
    padding: 15px 0;
  }
  
  .popover__wrapper {
    position: relative;
    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: translate(0, 10px);
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;


    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.1s cubic-bezier(0.75, -0.02, 0.2, 0.97);

    left: 0px;
    top: -100px;
    

  }
  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  .popover__message {
    text-align: center;
  }
  


.link{
  cursor: pointer;
  text-decoration: underline;
}

.cursorPointer{
  cursor: pointer !important;
 
}

#EasyPrintAnd{
  background: red;
}

.font-s-10{
  font-size: 10px !important;
}
.font-s-11{
  font-size: 11px !important;
}
.font-s-12{
  font-size: 12px !important;
}


