

#documento{
   

  
}

.ocultar{
    border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;overflow: hidden;
}

.cuentasAbiertas{
    background: rgb(191, 198, 195);top: 0px;position: absolute;z-index: 10;height: 100vh;
    overflow: auto;
    width: 100%;
}


.font-s-candado{
    font-size: 7px !important;
  }
.link{
    cursor: pointer;
    text-decoration: underline;
  }
  

.table-item  td {
    text-align: center;
    vertical-align: middle;
    padding: 0px;
}

.active-tr-item{
    border: solid 2px #1f2b25 !important;
    background: #43cd6652;

}

.active-tr-item >td {

    background: #43cd6652 !important;

}





/**** Estilo PARA LA LISTA DE PRODUCTOS ***/

.producto-nombre{
    font-size: 1.40rem;
    color: #c6dbcc;
}

.contenedor-items{
   
    padding-top:10px;
    padding-left: 5px;
}

.contenedor-items-only-items{
    border-left: solid 0.5px #b8e3c3  !important;
    border-bottom: solid 0.5px #b8e3c3  !important;;

    background: rebeccapurple;
    background: azure;
    height: 75vh;
    overflow: auto;
    -webkit-box-shadow: inset -16px 16px 12px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: inset -16px 16px 12px -18px rgba(0,0,0,0.75);
    box-shadow: inset -16px 16px 12px -18px rgba(0,0,0,0.75);
}


/*Container max-width	None (auto)	540px	720px	960px	1140px	1320px


@media (max-width :  3000px) {
    .altoItems{
        height: 75vh;
        
    }   
}

@media (max-width :  1080px) {
    .altoItems{
        height: 75vh;
      
    }   
}
@media (max-width: 960px) {
    .altoItems{
        height: 70vh;
      
    }   
}
@media (max-width : 720px) {
    .altoItems{
        height: 65vh;
        
    }   
}

@media (max-width : 540px) {
    .altoItems{
        height: 50vh;
      
    }   
}

*/






.m-flex{
    display: flex;
    justify-content: space-around; 
    align-items: stretch; 
    flex-direction: column; 
    flex-wrap: nowrap; 
    align-content: stretch;
    max-width: 200px;

   
    
    
}

.precios-total{
    align-self:flex-end;
}



.total-pagar{
    width: 180px;
    font-size: 1.5vw;
    color: #43cd66;
    font-weight: 500;
}

.tipo_de_documento{

    align-items: center;
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    column-gap: 16px;
    display: inline-flex;
    font-size: 2vh;
    font-weight: 500;
    height: 30px;
    justify-content: center;
    padding: 10px 30px;
    border-color: rgb(0 0 0 / 0%);
    color: #ffffff;
    cursor: pointer;

    
}

.tipo_de_documento:hover {
    opacity: .75;
    transition: opacity ease-in-out 250ms ;
    background: #dee2e6 !important;
}



.nav-tabs .nav-link {

    height: 100%;

}


.nav-tabs {
    border-width: 0px; 
    border-color: none;


}


/******* ACTIVES NAV ***/

 .active-primary {
    background-color: #0d6efd !important;
    color: white !important;
    border-color: rgba(196, 11, 11, 0) !important;
}



.active-warning {
    background-color: #ffcc00 !important;
    color: #494538 !important; 
    border-color: rgba(196, 11, 11, 0) !important;
}

.active-danger {
    background-color: #dc3545 !important;
    color: #ffffff !important; 
    border-color: rgba(196, 11, 11, 0) !important;
}


.bg-principal , .active-success{
    background: #43cd66;
    color: #ffffff !important; 
    border-color: rgba(196, 11, 11, 0) !important;
}

.active-principal {
    background-color: #43cd66 !important;
    color: white !important;
    border-color: rgba(196, 11, 11, 0) !important;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
   
    
    isolation: unset;
    /*****
    isolation: isolate;
    border-color: rgba(255, 0, 0, 0) !important;
    **/
}







/***** foooter ******/
.site-footer {
    font-family: var(--cp-font-family);
    height: 20px;
    line-height: 1;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    white-space: nowrap;
    color: var(--cp-color-9);
    background: var(--cp-color-14);
    border-top: 1px solid var(--cp-color-13);
    z-index: 1100;
    font-size: 1rem;
    background: #103928;
    color: #43cd66;

    box-shadow: -1px -7px 23px -1px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px -7px 23px -1px rgba(0,0,0,0.75);
-moz-box-shadow: -1px -7px 23px -1px rgba(0,0,0,0.75);
}



.footer-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;


    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    height: 100%;
    padding: 9px 0;
    z-index: 1;
}

.flash {
 
    
    animation-name: example;
    animation-duration: 0.4s;
  }
  @keyframes example {
    from {background-color: red;}
    to {background-color: yellow;}  
  }

  .comentario-div {
    width: 300px; /* Ancho del div */
    padding: 20px; /* Espaciado interno */
    background-color: #f0f0f0; /* Fondo gris claro */
    border: 2px dotted #ccc; /* Borde gris */
    text-align: center; /* Centrar el texto horizontalmente */
    margin: 0 auto; /* Centrar el div horizontalmente en la página */
  }

  .estiloPrecios{
    background: rgb(179, 179, 179);
    position: absolute;
    left: 0px;
    bottom: 20px;
    z-index: 5;
    width: 100%;
  }

  .saldoFavorFlotante{
    background: yellow;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    margin-top: -30px;
    padding: 2px 5px;

  }













/*** Boostrap Modificado **/

  
.btn-close-venta{
    margin: auto;
    font-size: .7rem;
    margin-top: 12%;
    margin-right: 5px;
    font-weight: 800;
}

.btn-close-venta:hover{
   background: var(--bs-danger);
   color: white !important;
   opacity: .9;
}

.margin-5{
    margin-right: 5px;
}

.max-200{
    max-width: 200px !important;
}

.max-150{
    max-width: 150px !important;
}

.max-50{
    max-width: 50px !important;
}

.max-70{
    max-width: 100px !important;
}



.min-width-400{
    min-width: 400px !important;
}

.select_tipo_documento{
    appearance:auto ;
    padding: 0.2rem 0.1rem; 
    font-size: 0.8rem;
    max-width: 100px !important;
}

.resultado-clientes{
    position: fixed;
 
}

.form-online{
    margin-top: 6px;
    margin-right: 5px;
}


.label-form{
    font-size: 10px;
    columns: #ccc;
}



.nav-documento{
   /* flex-wrap :nowrap;*/
    overflow-y: none;
    width:inherit;
    z-index: 1000;
    display: block;
}



.nav-tabs-documento{
 
    background: #103928;    

    background: #103928;
    overflow-x: auto;

}


.pestanas_nav{

    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;

    flex-wrap: wrap;
    align-content:center;
  
    
    
}

.fijos-nav{
    break-after: blue;
    width: 0%;
    display: flex;
    overflow: hidden;
}

.nav-tabs-documento {
    border-radius: 0px;
    margin: 0px !important;
    padding: 0px !important;
}

.nav-tabs-documento .nav-link-documento {
    font-size: 13px;
    border-radius: 0px ;
    min-width: 100px;
    color: #43cd66;
    font-weight: 500;
    padding: 4px 15px 4px 15px;
 
}
.nav-link-total{
    display: block;
    margin: 0px;
    padding: 0px;
   
    font-size: 10px;
 
    
}

.nav-link-titulo{
    display: block;
    margin: 0px;
    padding: 0px;
 
    
}

.contenedor-nav{
    width: 100%;
    position: relative;
 

}


.active-principal , .active-success > span {
    color: #103928 !important;
}

.documento-body{

}



.link-nav-pt{
    margin: 0 10px;
    font-size: 18px;
    color: #43cd66;
    cursor: pointer;
  

}

.click{
    cursor: pointer;
}

.nav-item-documento:hover{
    border: none;
}

.nav-item-documento{
    border-left: solid 1px;
}

.link:hover {
    isolation: isolate; 
    border-color: none;
}
.nav-item-documento > .active-principal{

    border: 0px;
}


/*** Form css **/
.iconalign {
    top: -30px;
    position: relative;
    padding: 11px;
}

.form-control-icons {
    padding-left: 36px !important;
    margin-top: -30px
}

.form-button-submit-mini{
    
    margin-top: -51px
}

.contanedor-form{
    background-color: rgb(255, 255, 255);
    max-width: 300px;
    width: 300px;
    padding: 5px 10px;

    position: absolute;
    inset: 0px auto auto 0px;
    margin-top: 29px;

    border: solid 2px #198754;
}








.table>:not(caption)>*>* {
     padding: 0.0rem 0.0rem; 
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.card-precio-defecto{

    max-width: 500px;
    position: absolute;
    top: 10%;
    left: 30%;
    z-index: 300;

    box-shadow: 3px -9px 96px 16px rgba(0,0,0,0.54);
-webkit-box-shadow: 3px -9px 96px 16px rgba(0,0,0,0.54);
-moz-box-shadow: 3px -9px 96px 16px rgba(0,0,0,0.54);
}

.input-precio-cantidad{
    font-size: 50px;
    width: 80%;
}



/*** Layaut **/
.flexbox-container {
    display: -ms-flex;
    display: flex;
    height: 93.5vh;
}

.flexbox-container>div {
    
    overflow: auto;
 
}

.columna-1 {
    background: #fff;
    
}

.columna-2 {
    color: #43CD66;
    background: #103928;
}


.MOSTRAR__productos_vender {

}

.contenedor-input{
    width: 100%;
    display: flex;
 
    justify-content: center;
}


.inputBuscador::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: hsl(135, 20%, 39%);
}


.inputBuscador{
  
    text-transform: uppercase;
    font-size: 25px;
    background: #081f16 !important;
 
    color: #43CD66;
    font-family: sans-serif;
    font-weight: 700;
    height: auto;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: solid 2px;
    border-color: #1b563a;
}

.precioList{
    font-size: 20px;
    color: #00ff41;
    font-weight: 500;
}
   


.form-check-input-buscar {
    width: 4em !important;
    height: 20px !important;

}

.form-check-input:checked {
    background-color: #43cd66;
    border-color: #43cd66;
}
.inputBuscador:focus {
    border-color: #0d6efd;
    outline: none;
    -webkit-box-shadow: -4px 0px 1px 0px #0d6efd;
    -moz-box-shadow: -4px 0px 1px 0px #0d6efd;
    box-shadow: 0px 0px 8px 0px #0d6efd;
    font-size: 25px;
    border: #0d6efd solid 4px;



}

.list-group-productos-items-all{
    overflow-y: auto;
 
    background: #081f16 !important;
 

    -webkit-box-shadow: inset 0px 0px 44px 14px rgba(2,31,12,1);
    -moz-box-shadow: inset 0px 0px 44px 14px rgba(2,31,12,1);
    box-shadow: inset 0px 0px 44px 14px rgba(2,31,12,1);
}


.list-group-item-action:hover{
    color: #01c96c;
    background-color: rgb(72 83 79 / 38%);
}


.list-group-item-list-producto {

    display: block;
    padding: 10px 10px;
    color: #c7e6d7;
    text-decoration: none;
    background-color: #ffffff26;
    border: 0.8px solid #378e4e;
}


.list-group-item-action:active ,  
.list-group > .active{
    color: #01c96c;
    background-color: #0d6efd;
}



.list-group-item-action:focus{
    color: #01c96c;
    background-color:#001b18;
}


.list-group-item-list-producto:first-child  {
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius: var(--bs-border-radius);
    border-top-width: 1px;
}





/**  DISEÑOS DE FACTURA  ***/
.textoo{
    white-space: nowrap;
}

.nav-item-document{
    border-right: 1px solid;
    background: #43cd66;
    color: #1c3f32 !important;

}

.input-invisible  {
    border: none;
  }
  
  /* Estilo para el input cuando está enfocado */
  .input-invisible:focus {
    border: none; /* Elimina el borde cuando el input está enfocado */
  }

  .input-invisible:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}


@keyframes destello {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.destello {
    animation: destello 1s infinite alternate;
}


.mensaje-error {
    background: #f1d5d5;
    font-size: 24px;
    padding: 20px;
    border: 2px solid #ff0000;
    border-radius: 5px;
    color: #ff0000;
    height: 50%;
}


.resultado-clientes .list-group-item div:hover {
    background-color: #f0f0f0;
    padding: 0px 5px;
    border-radius: 5px;
}