.text-principal-2{
    color: #103928 ;
   
  }


  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgb(246 247 249) ;
}

.p7-fa-total {
    font-size: 3rem;    
    height: 100px;
    text-align: center;
  }


/***** BTN PRINCIPAL ***/
.btn-principal {
    background: #2dd504;
    color: #103928;
    font-weight: 600;
}
.btn-principal:hover {
    opacity: .75;
    transition: opacity ease-in-out 250ms;
    background: #2f9147;
}




/***** BTN PRINCIPAL ***/
.btn-principal {
    background: #2dd504;
    color: #103928;
    font-weight: 600;
}
.btn-principal:hover {
    opacity: .75;
    transition: opacity ease-in-out 250ms;
    background: #2f9147;
}



.btn-life {
    color: #fff;
    background-color: #9c27b0;
    border-color: #9c27b0;
}

.bg-life {
    color: #fff;
    background-color: #9c27b0;
    border-color: #9c27b0;
}

.active-life {
    background-color: #9c27b0 !important;
    color: #fff !important;
    border-color: rgba(196, 11, 11, 0) !important;
}


.btn-xs {
    padding: 0.175rem 0.95rem;
    font-size: 10px;
    margin: 2px 3px !important;

}
.btn-xxsss {
    padding: 0.0rem 0.5rem;
    font-size: 9px;
    margin: 0px 0px !important;

}



.text-helper{
    color: rgb(57, 63, 75);
    font-size: 11px;
}




/****** FORMULARIOS   ***/
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #2dd504;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #2dd5043b;
}




#EasyPrintAnd{

    border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;overflow: auto;

  }
  


/**** BODERS ***/
.border-principal{
    border-color:  #2dd504;
}


.bg-principal{
    background: #2dd504;
}


.bg-segundo{
    background: #103928;
}
.text-principal{
    color: #2dd504;
}



/* dropdown */
.after-dropdown-toggle::after { 
  display: none !important;
}


.bd-callout-info {
    --bd-callout-color: var(--bs-info-text-emphasis);
    --bd-callout-bg: var(--bs-info-bg-subtle);
    --bd-callout-border: var(--bs-info-border-subtle);
}

.bd-callout {
    --bs-link-color-rgb: var(--bd-callout-link);
    --bs-code-color: var(--bd-callout-code-color);
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: var(--bd-callout-color, inherit);
    background-color: var(--bd-callout-bg, var(--bs-gray-100));
    border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}


.modal-fullscreen-90 {
  
    width: 95vw !important;
    max-width: none;
    height: 98% !important;
    margin: auto !important; 
 
}